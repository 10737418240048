import styled from 'styled-components'
import PropTypes from 'prop-types'

export const OpacityAnimation = styled.div`
  transition:.6s;
  opacity: ${({ state }) => (state === "entered" ? 1 : 0)};
`

export const TranslateOpacityAnimation = styled(OpacityAnimation)`
    transform: translate(
        ${({ state, translateX, translateY}) => (state === "entered") ? `0, 0` : `${translateX}, ${translateY}`}
    );
`

TranslateOpacityAnimation.propTypes = {
    translateX: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    translateY: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
}


TranslateOpacityAnimation.defaultProps = {
    translateY: `20px`
}