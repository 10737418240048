import PropTypes from "prop-types"
import React from "react"
import styled from 'styled-components'

import { Transition } from "react-transition-group"

import { TranslateOpacityAnimation } from '../styles/animations'

const Branding = styled.div`
    margin: 0 auto;
    max-width: 960px:
    width: 100%;
    padding: 1.45rem 1.0875rem 1.45rem;
    
    @media (min-width: 768px) {
        padding-top: 1.9rem;
    }
`

const Header = ({ siteTitle }) => (
  <header
style={{
      background: `#000`,
      marginBottom: `1.45rem`,
    }}
  >
<Transition in={true} appear={true} timeout={200}>
{(state) => (
<TranslateOpacityAnimation state={state} translateX={`-20px`} translateY={0}>
    <Branding>
      <h1 style={{
          margin: 0,
          textTransform: `uppercase`,
        }}>
          {siteTitle}
      </h1>
    </Branding>
    </TranslateOpacityAnimation>
)}
</Transition>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
