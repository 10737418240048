/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import styled, { createGlobalStyle } from 'styled-components'

import Header from "./header"
import Footer from "./footer"
import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

    return (
        <>
            <GlobalStyle />
            <SiteWrapper>
                <Header siteTitle={data.site.siteMetadata.title} />
                <Main>
                    {children}
                </Main>
                <Footer />
            </SiteWrapper>
        </>
    )
}

const GlobalStyle = createGlobalStyle`
    body {
        margin: 0;
        color: #FFF;
        background-color: #000;
        font-family: 'Open Sans', Roboto, "Helevetica Neue", sans-serif;
        font-size: 1.125rem;
        font-weight: normal;
    }
`

const SiteWrapper = styled.div`
    min-height: 100vh;
    display: flex;
    flex-flow: column wrap;
    align-items: stretch;
    overflow-x: hidden;
`

const Main = styled.main`
    margin: 0 auto,
    max-width: 960;
    width: 100%;
    padding: 0 1.0875rem 1.45rem;
    display: flex;
    flex-flow: column nowrap;
    flex-grow: 1;
    justify-content: center;
`

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
