import React from "react"
import styled from 'styled-components'

import { Transition } from "react-transition-group"

import { TranslateOpacityAnimation } from '../styles/animations'

const FooterContainer = styled.footer({
    margin: `0 auto`,
    maxWidth: 960,
    width: `100%`,
    padding: `1.45rem 1.0875rem`,
    fontSize: `.625rem`,
    fontFamily: `Heebo, Roboto, "Helevetica Neue", sans-serif`,
    textTransform: `uppercase`,
    color: `rgba(255, 255, 255, 0.8)`,
    textAlign: `right`,
    fontWeight: 300,
})

const Footer = () => (
    <Transition in={true} appear={true} timeout={1000}>
        {(state) => (
            <TranslateOpacityAnimation state={state} translateX={`10px`} translateY={0}>
                <FooterContainer>
                    © {new Date().getFullYear()} Thibault Rivrain
                </FooterContainer>
            </TranslateOpacityAnimation>
        )}
    </Transition>
)

export default Footer